import React, { useEffect } from 'react';

const useImageTextDiff = ({
	refImage,
	refText,
}: {
	refImage: React.RefObject<HTMLDivElement>;
	refText: React.RefObject<HTMLDivElement>;
}) => {
	useEffect(() => {
		const image = refImage.current;
		const text = refText.current;
		if (!image || !text) return;

		const handleResize = () => {
			if (window.innerWidth < 767) {
				const bbText = text.getBoundingClientRect();
				const bbImage = image.getBoundingClientRect();

				if (bbText.bottom > bbImage.top) {
					const diff = bbText.bottom - bbImage.top;
					image.style.transform = `translateY(${diff}px)`;
				} else if ( bbText.bottom < bbImage.top) {
					image.style.transform = `translateY(0px)`;
				}
			} else {
				image.style.transform = `translateY(0px)`;
			}
		};

		window.addEventListener('resize', handleResize);
		handleResize();
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);
};

export default useImageTextDiff;
