import { useGSAP } from "@gsap/react";
import React, { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { useGenerationStore } from "src/hooks/useGenerationStore";
import { useTextTransitions } from "src/hooks/useTextTransitions";
import parser from 'html-react-parser'

interface Slide8Props {
    data: any;
    blocks: any;
}
interface CardProps {
    subheading: string;
    title: string;
    link: {
        text: string;
        url: string;
    },
    background: string;
}

const Slide8: React.FC<Slide8Props> = ({ data, blocks }) => {
    const slide8Ref = useRef<HTMLDivElement>(null);
    const { setTimelineSlide8, isStartSkip, tlInfiniteBgArray, setIsAnimating, setActiveSlide, setProgress, tlInfiniteBg } = useGenerationStore();
    const [hoveredImageIndex, setHoveredImageIndex] = useState<number | null>(null);

    useTextTransitions({ mainRef: slide8Ref });

    useGSAP(() => {
        const main = slide8Ref.current;
        if (!main) return;

        const tl = gsap.timeline({ paused: true })
            .from('.lineChild', {
                yPercent: 100,
                duration: 1,
                stagger: 0.1,
                ease: 'power4.out',
            })
            .from('[data-buttons]', {
                y: '100%',
                alpha: 0,
                duration: 1,
                ease: 'power4.out',
                stagger: 0.1,
            }, '-=1');


        const tlImage = gsap.timeline({ paused: true });

        tlImage.from('[data-image]', {
            yPercent: 100,
            duration: 1,
            ease: 'power4.out',
        }, 0);

        if (isStartSkip) {
            if (tlInfiniteBgArray) {
                setIsAnimating(true);
                slide8Ref.current.classList.add('active');
                setActiveSlide(8);
                setProgress(8);
                setTimeout(() => {
                    document.documentElement.setAttribute(
                        'data-theme',
                        'dark'
                    );
                    tl.play();
                    tlImage.play();
                    tlInfiniteBg.timeScale(10).reverse();
                    gsap.to(tlInfiniteBgArray.element, {
                        opacity: 0,
                        duration: 1,
                        onComplete: () => {
                            setIsAnimating(false);

                        }
                    });
                }, 2500);
            }
        }

        setTimelineSlide8({
            tl,
            tlImage
        });

    }, { scope: slide8Ref, dependencies: [isStartSkip] })

    const handleMouseEnter = (index: number) => {
        cardListRef.current?.querySelectorAll('a').forEach((item, i) => {
            item.classList.remove('bg-secondary/90');
        });
        setHoveredImageIndex(index);

    }

    const handleMouseLeave = (index: number) => {
        setHoveredImageIndex(null);
        cardListRef.current?.querySelectorAll('a')[0].classList.add('bg-secondary/90');
    }
    
    const cardListRef = useRef<HTMLDivElement>(null);
    return (
        <section className="flex items-start justify-center overflow-hidden slide" ref={slide8Ref} tabIndex={8}>
            <div className="w-full max-w-[1600px] relative h-full flex flex-col justify-end text-center">
                <p className="mb-2.5 dark:text-black kicker" data-text-animation>{data.kicker}</p>
                <div className="flex flex-col md:flex-row mb-12.5">
                    <div className="w-full max-w-3xl mx-auto">
                        <h3 className="dark:text-black" data-text-animation>{parser(data.subheading)}</h3>
                    </div>
                </div>

                <div className="p-3.75 md:p-12.5 relative h-[60vh]" data-image>
                    <div className="absolute left-0 top-0 h-full w-full blur-[2px]" >
                        <img
                            src={blocks[0].background[0]}
                            alt="Slide1"
                            className={`absolute object-cover w-full h-full transition-all duration-1000`}
                        />
                        {
                            blocks && blocks.map((item: CardProps, index: number) => (
                                <img
                                    key={index}
                                    src={item.background}
                                    alt={`Slide ${index + 1}`}
                                    className={`absolute object-cover w-full h-full transition-all duration-1000 ${hoveredImageIndex === index ? 'opacity-100' : 'opacity-0'}`}
                                />
                            ))
                        }
                    </div>
                    <div className="flex flex-col gap-3.5 md:gap-0 md:flex-row z-10 relative h-full" ref={cardListRef}>
                        {blocks.map((item: CardProps, index: number) => (
                            <a
                                href={item.link.url}
                                key={index}
                                className={`relative w-full h-full py-3 px-5 md:p-7.5 group ${index === 0 ? 'bg-secondary/90' : ''} ${hoveredImageIndex === index ? 'bg-secondary/90' : ''} transition-all duration-500 border border-white ${index === 1 ? 'md:border-l-0 md:border-r-0 border-white' : ''}`}
                                onMouseEnter={() => handleMouseEnter(index)}
                                onMouseLeave={() => handleMouseLeave(index)}
                                // target="_blank"
                                rel="noreferrer"
                                tabIndex={-1}
                            >
                                <div className="relative flex flex-col justify-center h-full ">
                                    <p className="text-[12px] md:text-[16px]  text-white font-lora" data-text-animation>{item.subheading}</p>
                                    <p className="font-bold text-white text-5.5 md:text-7 font-lora" data-text-animation>{item.title}</p>
                                    <p className="relative mx-auto text-[12px] md:text-[16px] text-white w-max before:absolute before:w-full before:h-px before:bg-white before:bottom-0 before:left-0 group-hover:before:scale-x-0 before:origin-left before:transition-all" data-buttons>
                                        {item.link.text}
                                    </p>
                                </div>
                            </a>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};
export default Slide8;
