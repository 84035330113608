import React from "react";

interface ImageProps {
    src: string;
    alt: string;
    width?: number;
    height?: number;
    className?: string;
}

const Image: React.FC<ImageProps> = ({ src, className, ...props }) => {
    return React.createElement("img", { src, ...props, className: className})
};

export default Image;