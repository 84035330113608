import { useGSAP } from "@gsap/react";
import { useRef } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useTextTransitions } from "src/hooks/useTextTransitions";
import { useGenerationStore } from "src/hooks/useGenerationStore";
import { Tooltip } from "src/components/Tooltip/Tooltip";
import parser from 'html-react-parser'
import useImageTextDiff from "src/hooks/useImageTextDiff";

const Slide5 = ({ data }: { data: any }) => {
    const mainRef = useRef<HTMLDivElement>(null);
    const refText = useRef<HTMLDivElement>(null);
    const refImage = useRef<HTMLDivElement>(null);

    useImageTextDiff({
        refImage,
        refText,
    });

    useTextTransitions({ mainRef });
    const { setTimelineSlide5 } = useGenerationStore();
    useGSAP(() => {
        gsap.registerPlugin(ScrollTrigger);
        const main = mainRef.current;
        if (!main) return;

        const allText = main.querySelectorAll('.lineChild');

        const tl = gsap.timeline({ paused: true })
            .from(allText, {
                y: '100%',
                duration: 1,
                stagger: 0.1,
                ease: 'power4.out',
            }, 0)
     

        const tlImage = gsap.timeline({
            paused: true,
            delay: 1,
        }).from('[data-image]', {
            clipPath: 'inset(100% 0 0 0)',
            duration: 2,
            ease: 'power3.out',
        })
            .from('[data-image-bg]', {
                blur: 12,
                duration: 2.5,
                ease: 'power4.inOut'
            }, 1)
            .from('[data-image-overlay]', {
                duration: 3,
                x: '30%',
                ease: 'power4.inOut'
            }, 0);
            
        const elementImage = main.querySelector('[data-image]');
        setTimelineSlide5({
            tl,
            tlImage,
            elementImage
        });
    }, { scope: mainRef })
    return (
        <section className="relative flex items-start md:items-center slide" ref={mainRef} tabIndex={5}>
            <div className="z-10 flex flex-col container-full lg:flex-row" ref={refText}>
                <div className="w-full pt-20 md:pt-0 lg:w-1/2">
                    <h2 className="mb-5 text-white" data-text-animation>{data.title}</h2>
                    <h3 className="max-w-xl mb-5 text-white md:mb-20 " data-text-animation>
                        {parser(data.subheading)}
                        <Tooltip id="tooltip-slide-5" content={data.citation} />
                    </h3>
                    {/* <span className="inline-block mb-5 tooltip md:mb-20">
                    </span> */}
                    <p className="max-w-md font-light text-white commentary" data-text-animation>{data.commentary}</p>
                </div>
            </div>

            <div className="absolute bottom-0 right-0 w-full lg:w-1/2" ref={refImage}>
                <div className="relative overflow-hidden image-double" data-image>
                    <img src="/gg-slider-assets/images/blurred/an_injured_child_while_held_by_a_young_boy.jpg" alt="Slide4" className="relative object-cover w-full h-full" data-image-bg />
                    <img src="/gg-slider-assets/images/blurred/an_injured_child_while_held_by_a_young_boy-overlay.png" alt="Slide4" className="absolute top-0 object-cover w-full h-full" data-image-overlay />
                </div>
            </div>
        </section>
    );
}

export default Slide5;