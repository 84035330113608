import React from 'react';

const Share: React.FC = () => {
    return (
        <span className='group'>
            <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M15.5621 5.46655C15.5621 2.39068 19.3024 0.839953 21.4818 3.01713C23.6613 5.1943 22.1089 8.93072 19.0298 8.93072C17.9551 8.93072 16.9845 8.44095 16.3436 7.65747L9.21093 11.7712C9.51072 12.5599 9.51044 13.4418 9.21038 14.2302L16.3425 18.3437C18.403 15.825 22.4973 17.2921 22.4973 20.5335C22.4973 23.6097 18.7568 25.1598 16.5776 22.9829C15.6201 22.0265 15.2983 20.5874 15.787 19.3034L8.65485 15.19C6.59158 17.7095 2.5 16.2397 2.5 12.9999C2.5 9.756 6.59709 8.29355 8.65402 10.8092L15.7864 6.69552C15.6416 6.31355 15.5621 5.89912 15.5621 5.46655ZM17.366 18.8714C15.8877 20.3482 16.9399 22.8839 19.0298 22.8839C21.1195 22.8839 22.1722 20.3484 20.6937 18.8714C19.7748 17.9535 18.2846 17.9535 17.366 18.8714ZM4.30395 11.3378C2.82566 12.8145 3.87781 15.3503 5.96778 15.3503C8.05748 15.3503 9.11017 12.8148 7.63161 11.3378C6.71271 10.4201 5.22286 10.4201 4.30395 11.3378ZM17.366 3.80445C15.8877 5.2812 16.9399 7.81697 19.0298 7.81697C21.1195 7.81697 22.1722 5.28148 20.6937 3.80445C19.7748 2.8865 18.2846 2.8865 17.366 3.80445Z" className=' fill-white dark:fill-black group-hover:fill-secondary transition-all' />
            </svg>
        </span>

    );
}

export default Share;