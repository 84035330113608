import { useGSAP } from "@gsap/react";
import { useEffect, useRef } from "react";
import gsap from "gsap";
import { useTextTransitions } from "src/hooks/useTextTransitions";
import Swiper from "swiper";
import 'swiper/swiper-bundle.css';
import { Navigation, Pagination, Autoplay, EffectFade } from 'swiper/modules';
import { useGenerationStore } from "src/hooks/useGenerationStore";
import { Tooltip } from "src/components/Tooltip/Tooltip";
import parser from 'html-react-parser'

const Slide2 = ({ data }: { data: any }) => {
    const mainRef = useRef<HTMLDivElement>(null);
    const backgroundSwiper = useRef<HTMLDivElement>(null);
    const imageRef = useRef<HTMLDivElement>(null);

    const { setTimelineSlide2 } = useGenerationStore();
    // initiate before animation

    useTextTransitions({ mainRef });

    useGSAP(() => {
        const main = mainRef.current;

        if (!main) return;

        if (!backgroundSwiper.current) return;
        const background = backgroundSwiper.current;
        Swiper.use([Navigation, Pagination, Autoplay, EffectFade]);
        const swiper = new Swiper(background, {
            loop: true,
            speed: 5000,
            effect: 'fade',
            slidesPerView: 1,
            spaceBetween: 0,
            watchSlidesProgress: true,
            roundLengths: true,
            fadeEffect: {
                crossFade: true,
            },
            autoplay: {
                delay: 0,
                disableOnInteraction: false,
            },
            on: {
                init(e: any) {
                    const slide = e.slides[e.activeIndex].firstElementChild;
                    slide.style.transform = `translate3d(0, 0, 0)`;
                },

                slideChange(e: any) {
                    const slide = e.slides[e.activeIndex].firstElementChild;
                    slide.style.transform = `translate3d(0, 20px, 300px) scale(1)`;
                    slide.style.transition = `transform 10s linear`;
                },
                transitionEnd(e: any) {
                    // previous active slide to scale 1 again
                    const slide = e.slides[e.previousIndex].firstElementChild;
                    slide.style.transform = `translate3d(0, 0, 0)`;

                },
                // setTransition(e: any, t: any) {
                //     for (let r = 0; r < e.slides.length; r += 1) {
                //         const o = e.slides[r].firstElementChild;
                //         o.style.transitionDuration = `${t}ms`;
                //     }
                // },
            },

        });


        const tl = gsap.timeline({
            paused: true,
        }).from('.lineChild', {
            y: '100%',
            duration: 1,
            stagger: 0.05,
            // skewY: 20,
            // rotateX: 90,
            ease: 'power4.out',
        })

        const tlImage = gsap.timeline({
            paused: true,
        }).to(imageRef.current, {
            "--clipA": 0,
            "--clipB": 0,
            "--clipD": 0,
            x: '0%',
            y: '0%',
            rotate: '0deg',
            duration: 2,
            ease: 'expo.out',
        })

        setTimelineSlide2({ tl, tlImage });

        return () => {
            swiper.destroy(true, true);
        }

    }, { scope: mainRef, dependencies: [] });

    return (
        <section className="relative z-10 overflow-hidden slide" ref={mainRef} tabIndex={2}>
            <div className="relative flex items-center w-screen h-screen">
                <div className="absolute top-0 left-0 z-0 w-full h-screen bg-black swiper-image" data-image ref={imageRef}>
                    <div className="w-full h-ful " ref={backgroundSwiper}>
                        <div className="swiper-wrapper">
                            {
                                data.background.map((item: any, index: number) => {
                                    return (
                                        <div key={index} className="swiper-slide prespective-1000 after:absolute after:w-full after:h-full after:bg-black/50 after:top-0 after:left-0 "><img src={item} alt={`background` + index} className="object-cover w-full h-full origin-center will-change-transform" /></div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className="relative z-10 container-full ">
                    <p className="mb-5 text-white kicker" data-text-animation>{data.kicker}</p>
                    <h3 className="font-light text-white" data-text-animation>{parser(data.subheading)} <Tooltip id="tooltip-slide-2" content={data.citation} /></h3>


                </div>
            </div>
        </section>
    );
}

export default Slide2;
