
import React, { FC, useEffect, useState, useRef } from 'react';
import Image from 'src/components/Image/Image';
import clsx from 'clsx';
import gsap from 'gsap';
import verticalLoop from 'src/hooks/useVerticalLoop';
import Observer from 'gsap/Observer';
import { useGSAP } from '@gsap/react';
import { useTheme } from 'src/contexts/ThemeProvider';
import { useGenerationStore } from 'src/hooks/useGenerationStore';
import { debounce } from 'lodash';
interface InfiniteBackgroundProps {
    data: any;
    className?: string;
}


const InfiniteBackground: FC<InfiniteBackgroundProps> = ({ data, className }) => {
    const [width, setWidth] = useState(window.innerWidth);
    const mainRef = useRef<HTMLDivElement>(null);
    const { setTlInfiniteBgArray, setTlInfiniteBg, tlInfiniteBgArray, isStartSkip } = useGenerationStore();

    const getMarginClass = (index: number) => {
        return clsx(index !== data.length - 1 && 'mr-[1.5vw]');
    };

    const getMarginBottomClass = (index: number) => {
        return clsx('mb-[1.5vw]');
    }

    const { isStart, isStartWithParam } = useGenerationStore();

    const heightCalculation = () => {
        var height = .01 * window.innerHeight;
        document.documentElement.style.setProperty("--rvh", `${height}px`);
    }



    useEffect(() => {
        const screenWidth = window.innerWidth;
        const columnLength = data.length;
        const columnWidth = screenWidth / columnLength;
        if (columnWidth < 200) {
            setWidth(200);
            return
        }
        setWidth(columnWidth);
        heightCalculation();

        const handleResize = debounce(() => {
            const screenWidth = window.innerWidth;
            const columnLength = data.length;
            const columnWidth = screenWidth / columnLength;
            if (columnWidth < 200) {
                setWidth(200);
                return;
            }
            setWidth(columnWidth);
            heightCalculation();
        }, 10);


        window.addEventListener('resize', handleResize);


        return () => {
            window.removeEventListener('resize', handleResize);
        };


    }, []);

    useGSAP(() => {
        gsap.registerPlugin(Observer);
        const main = mainRef.current;
        if (!main) return;

        const tlArray: any[] = [];

        const allColumns = mainRef.current?.querySelectorAll('[data-col]');
        const speeds = [5, 7.2, 5, 7.2, 5]
        if (allColumns) {
            allColumns.forEach((column: any, index: number) => {
                const allImages = column.querySelectorAll('[data-image]');
                const tl = verticalLoop(allImages, {
                    // draggable: false,
                    snap: 1,
                    center: true,
                    speed: speeds[index],
                    repeat: -1,
                    // reversed: index % 2 === 0 ? false : true,
                });

                tlArray.push(tl);
            });
        }

        const tlInfiniteBg = gsap.timeline({
            paused: true,
            // onReverseComplete: () => {
            //     gsap.to(main, { opacity: 0, duration: 1 })
            // }
        })
        tlInfiniteBg
            // .to(tlArray, { timeScale: 1.25, duration: 0.5 })
            .to(tlArray, { timeScale: 10, duration: 2, ease: 'power2.inOut' })
            .to(main, { scale: 2, duration: 2, ease: 'power2.inOut' })
            .to(tlArray, { timeScale: 0.09, duration: 1, ease: 'power1.inOut' }, '-=1')

        setTlInfiniteBg(tlInfiniteBg);
        const element = mainRef.current;
        setTlInfiniteBgArray({ tlArray, element });

        // HANDLE IF STARTED
        if (isStart) {
            tlInfiniteBg.play();
        }

        if (isStartSkip) {
            tlInfiniteBg.play();
        }



    }, { scope: mainRef, dependencies: [isStart, isStartSkip] });

    return (
        <div className={`will-change-transform z-0 fixed top-0 left-0 flex heightInfinite w-full overflow-hidden before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-radient-ellipse-c from-[rgba(0,0,0,0.5)]  to-black to-90% before:z-10 pointer-events-none ${isStartWithParam ? 'opacity-0' : ''} ${className}`} ref={mainRef}>
            {data.map((item: any, index: number) => {
                return (
                    <div key={index} className={`flex-col ${getMarginClass(index)} transform  rotate-4.7 -translate-x-4 md:-translate-x-16 will-change-transform`} data-col>
                        {item.images
                            .map((image: any, index: number) => {
                                return (
                                    <div key={index} className='flex flex-col select-none will-change-transform aspect-square' style={{ width: `${width}px` }} data-image>
                                        <Image src={image.src} alt={image.alt} width={width} height={image.height} className={`${getMarginBottomClass(index)} aspect-square object-cover grayscale`} />
                                    </div>
                                )
                            })}
                        {item.images
                            .map((image: any, index: number) => {
                                return (
                                    <div key={index} className='flex flex-col select-none will-change-transform aspect-square' style={{ width: `${width}px` }} data-image>
                                        <Image src={image.src} alt={image.alt} width={width} height={image.height} className={`${getMarginBottomClass(index)} aspect-square object-cover grayscale`} />
                                    </div>
                                )
                            })}
                    </div>
                )
            })
            }
        </div >
    );
}
export default InfiniteBackground;