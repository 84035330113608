// import { useTheme } from 'src/contexts/ThemeProvider';
import { useGenerationStore } from './useGenerationStore';
import gsap from 'gsap';
// import React from 'react';

const useGoToSlides = () => {
	const {
		activeSlide,
		setActiveSlide,
		timelineSlide6,
		timelineSlide7,
		timelineSlide8,
		timelineSlide4,
		timelineSlide5,
		tlInfiniteBg,
		timelineSlide1,
		timelineSlide3,
		tlInfiniteBgArray,
		timelineSlide2,
		setProgress,
		setIsAnimating,
		lottie,
		setIsStartSkip,
	} = useGenerationStore();
	// const { onClickSkip } = useTheme();

	const goToSlide = (slide: number) => {
		const allSlidesElement = document.querySelectorAll('.slide');
		allSlidesElement.forEach((slide, i) => {
			slide.classList.remove('active');
		});

		document
			.querySelector(`[tabindex="${slide}"]`)
			?.classList.add('active');
		setProgress(slide);
		setActiveSlide(slide);

		if (slide === 1) {
			tlInfiniteBg.timeScale(1.5).play();
			gsap.to(tlInfiniteBgArray.element, {
				opacity: 1,
				duration: 1,
			});
			timelineSlide1
				.timeScale(2)
				.play()
				.eventCallback('onComplete', () => {
					setIsAnimating(false);
				});
		}

		if (slide === 2) {
			timelineSlide2.tlImage.timeScale(1).play();
			timelineSlide2.tl
				.timeScale(1)
				.play()
				.eventCallback('onComplete', () => {
					setIsAnimating(false);
				});
		}

		if (slide === 3) {
			if (lottie) {
				lottie.setSpeed(1.35);
				lottie.play();
			}
			timelineSlide3
				.timeScale(1)
				.play()
				.eventCallback('onComplete', () => {
					setIsAnimating(false);
				});
		}

		if (slide === 4) {
			gsap.set(timelineSlide4.elementImage, {
				alpha: 1,
			});

			timelineSlide4.tl
				.timeScale(1.5)
				.play()
				.eventCallback('onComplete', () => {
					if (lottie) {
						lottie.stop();
					}
					setIsAnimating(false);
				});

			timelineSlide4.tlImage.timeScale(1.5).play();
		}

		if (slide === 5) {
			timelineSlide5.tl
				.timeScale(1.5)
				.play()
				.eventCallback('onComplete', () => {
					setIsAnimating(false);
				});
			gsap.set(timelineSlide5.elementImage, {
				alpha: 1,
			});

			timelineSlide5.tlImage.timeScale(1.5).play();
		}

		if (slide === 6) {
			timelineSlide6.tl
				.timeScale(1.5)
				.play()
				.eventCallback('onComplete', () => {
					setIsAnimating(false);
				});
			gsap.set(timelineSlide6.elementImage, {
				alpha: 1,
			});

			timelineSlide6.tlImage.timeScale(1).timeScale(1.5).play();
		}

		if (slide === 7) {
			timelineSlide7.tl
				.timeScale(2)
				.play()
				.eventCallback('onComplete', () => {
					setIsAnimating(false);
				});

			timelineSlide7.tlImage.timeScale(1).play();
		}

		if (slide === 8) {
			if (activeSlide === 7) {
				timelineSlide7.tlImageZ.timeScale(1).play();
			}
			document.documentElement.setAttribute('data-theme', 'dark');
			timelineSlide8.tl
				.timeScale(2)
				.play()
				.eventCallback('onComplete', () => {
					setIsAnimating(false);
				});
			timelineSlide8.tlImage.timeScale(1).play();
		}
	};

	const handleClick = (slide: number) => () => {
		// if (isAnimating === false) {
		setIsAnimating(true);

		if (activeSlide === 0) {
			setIsStartSkip(true);

			if (tlInfiniteBg) {

				// tlInfiniteBg
				// 	.timeScale(2)
				// 	.play()
				// 	.eventCallback('onComplete', () => {});

				// setTimeout(() => {
				// 	gsap.to(tlInfiniteBgArray.element, { 
				// 		opacity: 0,
				// 		duration: 1,
				// 	});
				// 	tlInfiniteBg.timeScale(10).reverse();
				// 	goToSlide(slide);
				// }, 1000);
			}
		}

		if (activeSlide === 1) {
			tlInfiniteBg
				.timeScale(2)
				.reverse()
				.eventCallback('onReverseComplete', () => {
					gsap.to(tlInfiniteBgArray.element, {
						opacity: 0,
						duration: 1,
					});
					goToSlide(slide);
				});
			timelineSlide1
				.timeScale(2)
				.reverse()
				.eventCallback('onReverseComplete', () => {});
		}

		if (activeSlide === 2) {
			timelineSlide2.tlImage.timeScale(1.5).reverse();
			timelineSlide2.tl
				.timeScale(1.5)
				.reverse()
				.eventCallback('onReverseComplete', () => {
					goToSlide(slide);
				});
		}

		if (activeSlide === 3) {
			timelineSlide3
				.timeScale(3)
				.reverse()
				.eventCallback('onReverseComplete', () => {
					lottie.stop();
					goToSlide(slide);
				});
		}

		if (activeSlide === 4) {
			timelineSlide4.tl.timeScale(3).reverse();
			const exit = gsap
				.timeline({ paused: true })
				.to(timelineSlide4.elementImage, { alpha: 0, duration: 1 });
			exit.timeScale(1)
				.play()
				.eventCallback('onComplete', () => {
					timelineSlide4.tlImage
						.timeScale(10)
						.reverse()
						.eventCallback('onReverseComplete', () => {
							goToSlide(slide);
						});
				});
		}

		if (activeSlide === 5) {
			timelineSlide5.tl.timeScale(3).reverse();
			const exit = gsap
				.timeline({ paused: true })
				.to(timelineSlide5.elementImage, { alpha: 0, duration: 1 });
			exit.timeScale(1)
				.play()
				.eventCallback('onComplete', () => {
					timelineSlide5.tlImage
						.timeScale(10)
						.reverse()
						.eventCallback('onReverseComplete', () => {
							goToSlide(slide);
						});
				});
		}

		if (activeSlide === 6) {
			timelineSlide6.tl.timeScale(3).reverse();
			const exit = gsap
				.timeline({ paused: true })
				.to(timelineSlide6.elementImage, { alpha: 0, duration: 1 });
			exit.timeScale(1)
				.play()
				.eventCallback('onComplete', () => {
					timelineSlide6.tlImage
						.timeScale(10)
						.reverse()
						.eventCallback('onReverseComplete', () => {
							goToSlide(slide);
						});
				});
		}

		if (activeSlide === 7) {
			timelineSlide7.tlImage.timeScale(2).reverse();
			timelineSlide7.tl
				.timeScale(2)
				.reverse()
				.eventCallback('onReverseComplete', () => {
					goToSlide(slide);
				});
		}

		if (activeSlide === 8) {
			timelineSlide8.tl.timeScale(3).reverse();
			timelineSlide8.tlImage
				.timeScale(1)
				.reverse()
				.eventCallback('onReverseComplete', () => {
					document.documentElement.setAttribute(
						'data-theme',
						'light'
					);
					goToSlide(slide);
				});
		}
	};

	return { handleClick, goToSlide };
};

export default useGoToSlides;
