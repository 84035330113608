import React from "react";

export const Speaker: React.FC = () => {
    return (
        <>
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" className="group">
                <path fillRule="evenodd" clipRule="evenodd" d="M15.114 0.5L6.12609 6.6295V18.3705C8.29404 19.8424 10.4581 21.3205 12.6225 22.7988L12.651 22.8182C13.4719 23.3789 14.2928 23.9396 15.114 24.5V0.5ZM6.78193 17.9977V7.00229L14.4582 1.7675V23.2325L6.78193 17.9977Z" className="fill-white dark:fill-black group-hover:fill-secondary transition-all"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M5.19825 18.5323V6.46953H0V18.5323H5.19825ZM0.655837 7.16018H4.54241V17.8417H0.655837V7.16018Z" className="fill-white dark:fill-black group-hover:fill-secondary transition-all"/>
                <path d="M19.7459 2.95977C22.999 4.92968 25 8.57128 25 12.5229C25 16.4784 22.999 20.1161 19.7459 22.0899L19.4217 21.4974C22.4735 19.6452 24.3442 16.2312 24.3442 12.5229C24.3442 8.8185 22.4698 5.4045 19.4217 3.55231L19.7459 2.95977Z" className="fill-white dark:fill-black group-hover:fill-secondary transition-all"/>
                <path d="M21.4675 12.5232C21.4675 9.51733 19.7199 6.8136 17.063 5.71484L16.8245 6.35448C19.2317 7.35121 20.8154 9.79987 20.8154 12.5232C20.8154 15.2505 19.2317 17.6952 16.8245 18.692L17.063 19.3355C19.7199 18.2328 21.4675 15.533 21.4675 12.5232Z" className="fill-white dark:fill-black group-hover:fill-secondary transition-all"/>
            </svg>

        </>
    );
}


export const SpeakerOff: React.FC = () => {
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" className="group">
            <path fillRule="evenodd" clipRule="evenodd" d="M16.114 1.5L7.12609 7.6295V19.3705C9.29404 20.8424 11.4581 22.3205 13.6225 23.7988L13.651 23.8182C14.4719 24.3789 15.2928 24.9396 16.114 25.5V1.5ZM7.78193 18.9977V8.00229L15.4582 2.7675V24.2325L7.78193 18.9977Z" className="fill-white dark:fill-black group-hover:fill-secondary transition-all"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M6.19825 19.5323V7.46953H1V19.5323H6.19825ZM1.65584 8.16018H5.54241V18.8417H1.65584V8.16018Z" className="fill-white dark:fill-black group-hover:fill-secondary transition-all"/>
            <path d="M20.7459 3.95977C23.999 5.92968 26 9.57128 26 13.5229C26 17.4784 23.999 21.1161 20.7459 23.0899L20.4217 22.4974C23.4735 20.6452 25.3442 17.2312 25.3442 13.5229C25.3442 9.8185 23.4698 6.4045 20.4217 4.55231L20.7459 3.95977Z" className="fill-white dark:fill-black group-hover:fill-secondary transition-all"/>
            <path d="M22.4675 13.5232C22.4675 10.5173 20.7199 7.8136 18.063 6.71484L17.8245 7.35448C20.2317 8.35121 21.8154 10.7999 21.8154 13.5232C21.8154 16.2505 20.2317 18.6952 17.8245 19.692L18.063 20.3355C20.7199 19.2328 22.4675 16.533 22.4675 13.5232Z" className="fill-white dark:fill-black group-hover:fill-secondary transition-all"/>
            <line x1="0.646447" y1="25.6464" x2="25.3952" y2="0.89771" className="stroke-white dark:stroke-black group-hover:stroke-secondary transition-all"/>
        </svg>
    )
}
