import { useGSAP } from "@gsap/react";
import Logo from "../Logo/Logo";
import { useRef } from "react";
import gsap from "gsap";

const Loading = () => {
    const mainRef = useRef<HTMLDivElement>(null);

    useGSAP(() => {
        if (!mainRef.current) return;

        const allPath = mainRef.current.querySelectorAll('path');

        allPath.forEach((path, index) => {
            gsap.fromTo(path, {
                autoAlpha: 1,
                scale: 1,
            }, {
                autoAlpha: 0.5,
                scale: 0.98,
                duration: 2,
                delay: index * 0.01,
                ease:'none',
                repeat: -1,
            })
        })
    }, { scope: mainRef })
    return (
        <section className="fixed top-0 left-0 flex items-center justify-center w-full h-screen transition-all bg-black z-9999" ref={mainRef}>
            <div className="container">
                <div className="relative flex items-center justify-center">
                    <div className=" scale-100 md:scale-[2]">
                        <Logo />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Loading;