import { useState, useEffect, useRef } from 'react';
import { useGenerationStore } from './useGenerationStore';

function useVisitorDetection() {
	const isVisitedRef = useRef(false);
	const activeSlideRef = useRef(0);
	const isSlide8Ref = useRef(false);


	// useEffect(() => {
	// 	const unsub = useGenerationStore.subscribe((state, prevState) => {
	// 		const slide = state.activeSlide;

	// 		console.log('ENDANG', slide);
	// 	});
	// 	return () => {
	// 		unsub();
	// 	};
	// }, []);

	// Function to set a cookie
	const setCookie = (cookieName: string, value: string, days: number) => {
		let expires = '';
		if (days === 9999) {
			expires = '; expires=Fri, 31 Dec 9999 23:59:59 GMT';
		} else {
			expires = new Date(Date.now() + days * 864e5).toUTCString();
		}
		document.cookie =
			cookieName +
			'=' +
			encodeURIComponent(value) +
			'; expires=' +
			expires +
			'; path=/';
	};

	// Function to get a cookie by name
	const getCookie = (name: any) => {
		const cookies = document.cookie.split(';');
		for (let cookie of cookies) {
			const [cookieName, cookieValue] = cookie.split('=');
			if (cookieName.trim() === name) {
				return decodeURIComponent(cookieValue);
			}
		}
		return null;
	};

	return { setCookie, getCookie };
}

export default useVisitorDetection;
