import HomePage from './pages/Homepage';

function App() {
  return (
    <>
      <HomePage />
    </>
  );
}

export default App;
