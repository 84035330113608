import NiceModal from '@ebay/nice-modal-react';
import React from 'react';
import { ThemeProvider } from 'src/contexts/ThemeProvider';
interface LayoutProps {
    children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
    return (
        <NiceModal.Provider>
            <ThemeProvider>
                {children}
            </ThemeProvider>
        </NiceModal.Provider>
    )
}

export default Layout;