import { Howl } from 'howler';
import { useGenerationStore } from './useGenerationStore';
import { useEffect, useRef } from 'react';

export const useAudio = () => {
	// const [sound, setSound] = useState<Howl | null>(null);
	const { setSound } = useGenerationStore();
	const refSound = useRef<Howl | null>(null);

	useEffect(() => {
		const unsub = useGenerationStore.subscribe((state, prevState) => {
			refSound.current = state.sound;
		});
		return () => {
			unsub();
		};
	}, []);

	const playAudio = () => {
		if (refSound.current !== null) {
			refSound.current.play();
		} else {
			const audio = new Howl({
				src: ['./gg-slider-assets/sounds/ambient.mp3'],
				volume: 1.5,
				loop: true,
			});
			setSound(audio);
			audio.play();
		}
	};

	const stopAudio = () => {
		if (refSound.current !== null) {
			refSound.current.stop();
			setSound(null);
		}
	};

	const pauseAudio = () => {
		if (refSound.current !== null) {
			refSound.current.pause();
		}
	};

	const resumeAudio = () => {
		if (refSound.current !== null) {
			refSound.current.play();
		}
	};

	return { playAudio, stopAudio, pauseAudio, resumeAudio };
};
