import React from "react";
import { useGenerationStore } from "src/hooks/useGenerationStore";
import useGoToSlides from "src/hooks/useGotoSlides";
interface ProgressBarProps {
    maxCompleted: number;
    completed: number;
    color?: string;
    width?: string;
    height?: string;

}

export const ProgressBar: React.FC<ProgressBarProps> = ({
    maxCompleted,
    completed,
    color = "white",
    width = "100%",
    height = "1rem"
}) => {
    const percentage = (completed / maxCompleted) * 100;
    const { setHoverProgress, activeSlide, } = useGenerationStore();
    const { handleClick } = useGoToSlides();
    const handleMouseEnter = (slide: number) => () => {
        setHoverProgress(slide);
    }

    return (
        <div className="relative" style={{ width, height }}>
            <div className="relative z-10 transition-all duration-500 bg-white dark:bg-black" style={{
                height: `${percentage}%`,
                width,
            }}></div>
            <div className="absolute left-0 w-[800%] h-full top-0 z-20 flex flex-col" style={{ height: "100%" }}>
                <button onClick={handleClick(1)} onMouseEnter={handleMouseEnter(1)} className={` flex-grow w-full ${activeSlide === 1 ? 'pointer-events-none' : ''}`}></button>
                <button onClick={handleClick(2)} onMouseEnter={handleMouseEnter(2)} className={` flex-grow w-full ${activeSlide === 2 ? 'pointer-events-none' : ''}`}></button>
                <button onClick={handleClick(3)} onMouseEnter={handleMouseEnter(3)} className={` flex-grow w-full ${activeSlide === 3 ? 'pointer-events-none' : ''}`}></button>
                <button onClick={handleClick(4)} onMouseEnter={handleMouseEnter(4)} className={` flex-grow w-full ${activeSlide === 4 ? 'pointer-events-none' : ''}`}></button>
                <button onClick={handleClick(5)} onMouseEnter={handleMouseEnter(5)} className={` flex-grow w-full ${activeSlide === 5 ? 'pointer-events-none' : ''}`}></button>
                <button onClick={handleClick(6)} onMouseEnter={handleMouseEnter(6)} className={` flex-grow w-full ${activeSlide === 6 ? 'pointer-events-none' : ''}`}></button>
                <button onClick={handleClick(7)} onMouseEnter={handleMouseEnter(7)} className={` flex-grow w-full ${activeSlide === 7 ? 'pointer-events-none' : ''}`}></button>
                <button onClick={handleClick(8)} onMouseEnter={handleMouseEnter(8)} className={` flex-grow w-full ${activeSlide === 8 ? 'pointer-events-none' : ''}`}></button>
            </div>
            <div className="absolute top-0 left-0 w-full h-full bg-white/30" data-bg></div>
            <div className="absolute top-0 left-0 w-full h-full bg-white/30 animate-scrollDown" data-animation></div>
        </div>
    )
}