import { useEffect } from 'react';
import { useGenerationStore } from './useGenerationStore';
import gsap from 'gsap';

const useSlideByParams = () => {
	const {
		setIsStart,
		setActiveSlide,
		setProgress,
		timelineSlide3,
		setIsAnimating,
		lottie,
		isStartWithParam,
		setIsStartWithParam,
		timelineSlide1,
		timelineSlide2,
		timelineSlide4,
		timelineSlide5,
		timelineSlide6,
		timelineSlide7,
		timelineSlide8,
		tlInfiniteBg,
		tlInfiniteBgArray,
	} = useGenerationStore();

	useEffect(() => {
		const checkParameterOfSlides = () => {
			const urlParams = new URLSearchParams(window.location.search);
			const slide1 = urlParams.has('slide1');
			const slide2 = urlParams.has('slide2');
			const slide3 = urlParams.has('slide3');
			const slide4 = urlParams.has('slide4');
			const slide5 = urlParams.has('slide5');
			const slide6 = urlParams.has('slide6');
			const slide7 = urlParams.has('slide7');
			const slide8 = urlParams.has('slide8');

			const handleTransitiion = (slide: number) => {
				setIsStartWithParam(true);

				const allSlidesElement = document.querySelectorAll('.slide');
				allSlidesElement.forEach((slide, i) => {
					slide.classList.remove('active');
				});

				document
					.querySelector(`[tabindex="${slide}"]`)
					?.classList.add('active');
				setActiveSlide(slide);
				setProgress(slide);
			};

			if (slide1 === true) {
				handleTransitiion(1);
				if (timelineSlide1) {
					setTimeout(() => {
						tlInfiniteBg.timeScale(1.5).play();
						gsap.to(tlInfiniteBgArray.element, {
							opacity: 1,
							duration: 1,
						});
						timelineSlide1
							.timeScale(2)
							.play()
							.eventCallback('onComplete', () => {
								setIsAnimating(false);
							});
					}, 500);
				}
			}

			if (slide2 === true) {
				handleTransitiion(2);
				if (timelineSlide2) {
					setTimeout(() => {
						timelineSlide2.tlImage.timeScale(1).play();
						timelineSlide2.tl
							.timeScale(1)
							.play()
							.eventCallback('onComplete', () => {
								setIsAnimating(false);
							});
					}, 500);
				}
			}

			if (slide3 === true) {
				handleTransitiion(3);

				if (timelineSlide3) {
					setTimeout(() => {
						timelineSlide3
							.timeScale(1)
							.play()
							.eventCallback('onComplete', () => {
								setIsAnimating(false);
							});

						if (lottie) {
							lottie.setSpeed(1.35);
							lottie.play();
						}
					}, 500);
				}
			}

			if (slide4 === true) {
				handleTransitiion(4);

				if (timelineSlide4) {
					gsap.set(timelineSlide4.elementImage, {
						alpha: 1,
					});

					setTimeout(() => {
						timelineSlide4.tl
							.timeScale(1.5)
							.play()
							.eventCallback('onComplete', () => {
								if (lottie) {
									lottie.stop();
								}
								setIsAnimating(false);
							});

						timelineSlide4.tlImage.timeScale(1.5).play();
					}, 500);
				}
			}

			if (slide5 === true) {
				handleTransitiion(5);

				if (timelineSlide5) {
					gsap.set(timelineSlide5.elementImage, {
						alpha: 1,
					});

					setTimeout(() => {
						timelineSlide5.tl
							.timeScale(1.5)
							.play()
							.eventCallback('onComplete', () => {
								setIsAnimating(false);
							});

						timelineSlide5.tlImage.timeScale(1.5).play();
					}, 500);
				}
			}

			if (slide6 === true) {
				handleTransitiion(6);
				if (timelineSlide6) {
					gsap.set(timelineSlide6.elementImage, {
						alpha: 1,
					});

					setTimeout(() => {
						timelineSlide6.tl
							.timeScale(1.5)
							.play()
							.eventCallback('onComplete', () => {
								setIsAnimating(false);
							});

						timelineSlide6.tlImage.timeScale(1.5).play();
					}, 500);
				}
			}

			if (slide7 === true) {
				handleTransitiion(7);
				if (timelineSlide7) {
					setTimeout(() => {
						timelineSlide7.tl
							.timeScale(2)
							.play()
							.eventCallback('onComplete', () => {
								setIsAnimating(false);
							});

						timelineSlide7.tlImage.timeScale(1).play();
					}, 500);
				}
			}

			if (slide8 === true) {
				handleTransitiion(8);
				if (timelineSlide8) {
					setTimeout(() => {
						document.documentElement.setAttribute(
							'data-theme',
							'dark'
						);
						timelineSlide8.tl
							.timeScale(2)
							.play()
							.eventCallback('onComplete', () => {
								setIsAnimating(false);
							});
						timelineSlide8.tlImage.timeScale(1).play();
					}, 500);
				}
			}
		};

		checkParameterOfSlides();
	}, [
		timelineSlide1,
		timelineSlide2,
		timelineSlide3,
		timelineSlide4,
		timelineSlide5,
		timelineSlide6,
		timelineSlide7,
		timelineSlide8,
		setIsAnimating,
		lottie,
		isStartWithParam,
		setIsStartWithParam,
		setIsStart,
		setActiveSlide,
		setProgress,
		tlInfiniteBg,
		tlInfiniteBgArray,
	]);
};

export default useSlideByParams;
