import { Tooltip as ReactTooltip } from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css'


export const Tooltip = ({ id, content }: { id: string, content?: any }) => {
    return (
        <>
            <span data-tooltip-id={id} className="group inline-block mx-2 lineParent tooltip-parent">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="lineChild">
                    <circle cx="10" cy="10" r="9" className="fill-none group-hover:fill-white transition-all" />
                    <path d="M10 18.75C5.16751 18.75 1.25 14.8325 1.25 10C1.25 5.16751 5.16751 1.25 10 1.25C14.8325 1.25 18.75 5.16751 18.75 10C18.75 14.8325 14.8325 18.75 10 18.75ZM10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z" fill="white" />
                    <path d="M11.1634 8.23486L8.29961 8.59375L8.19707 9.06982L8.76103 9.17236C9.12725 9.26025 9.20049 9.39209 9.11992 9.7583L8.19707 14.0942C7.95537 15.2148 8.32891 15.7422 9.20781 15.7422C9.88896 15.7422 10.68 15.4272 11.0389 14.9951L11.1487 14.4751C10.8997 14.6948 10.5335 14.7827 10.2918 14.7827C9.94756 14.7827 9.82305 14.541 9.91094 14.1162L11.1634 8.23486Z" className="fill-white group-hover:fill-black transition-all" />
                    <path d="M11.25 5.625C11.25 6.31536 10.6904 6.875 10 6.875C9.30964 6.875 8.75 6.31536 8.75 5.625C8.75 4.93464 9.30964 4.375 10 4.375C10.6904 4.375 11.25 4.93464 11.25 5.625Z" className="fill-white group-hover:fill-black transition-all" />
                </svg>
            </span>
            <ReactTooltip
                id={id}
                place="right"
                opacity={1}
                style={{ backgroundColor: 'white', color: 'black', padding: '10px', maxWidth: '180px' }}
                clickable={true}
                className="tooltip"
                
            >
                <div className="text-left">
                    {/* <p className="text-black"><b className="text-black">Last Updated:</b> February 10 2024</p>
                    <a href="http://google.com" className="text-secondary uppercase underline">OCHA</a> */}
                    <div dangerouslySetInnerHTML={{ __html: content }}
                    >
                    </div>
                </div>
            </ReactTooltip>
        </>)
}
